import styled from 'styled-components'

export default styled.div`
  padding: 0 12px 5px 15px;
  margin: 0;
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
`

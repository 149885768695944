export default [
  { "id": "0", "short": "WOR", "longName": "World", "color": "black", "colorCode": "#000000"},
  { "id": "1", "short": "AFR", "longName": "Africa", "color": "CornflowerBlue", "colorCode": "#6495ed"},
  { "id": "2", "short": "AUS", "longName": "Australia", "color": "navy", "colorCode": "#000080"},
  { "id": "3", "short": "CAC", "longName": "Central Asia", "color": "blue", "colorCode": "#0000ff"},
  { "id": "4", "short": "CAN", "longName": "Canada", "color": "GreenYellow", "colorCode": "#ADFF2F"},
  { "id": "5", "short": "CHI", "longName": "China", "color": "teal", "colorCode": "#008080"},
  { "id": "6", "short": "CSA", "longName": "South America", "color": "lime", "colorCode": "#00ff00"},
  { "id": "7", "short": "EUR", "longName": "Western Europe", "color": "aqua", "colorCode": "#00ffff"},
  { "id": "8", "short": "IND", "longName": "India", "color": "maroon", "colorCode": "#800000"},
  { "id": "9", "short": "JPN", "longName": "Japan", "color": "purple", "colorCode": "#800080"},
  { "id": "10", "short": "MEA", "longName": "Middle East", "color": "olive", "colorCode": "#808000"},
  { "id": "11", "short": "MEX", "longName": "Mexico", "color": "gray", "colorCode": "#808080"},
  { "id": "12", "short": "ODA", "longName": "Rest of Asia", "color": "DarkOrange", "colorCode": "#ff8c00"},
  { "id": "13", "short": "OEE", "longName": "Eastern Europe", "color": "Peru", "colorCode": "#cd853f"},
  { "id": "14", "short": "RUS", "longName": "Russia", "color": "fuchsia", "colorCode": "#ff00ff"},
  { "id": "15", "short": "SKO", "longName": "South Korea", "color": "yellow", "colorCode": "#ffff00"},
  { "id": "16", "short": "USA", "longName": "USA", "color": "GoldenRod", "colorCode": "#daa520"},
]